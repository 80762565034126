import linkedIn from "../assets/linkedIn.png";
import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import api from "../assets/api.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

import adidas from "../assets/adidas.png";
import amazon from "../assets/amazon.png";
import asus from "../assets/asus.png";
import bolt from "../assets/bolt.png";
import nb from "../assets/nb.png";
import skype from "../assets/skype.png";
import spotify from "../assets/spotify.png";

import headshot from "../assets/headshot.JPG";
import logo_color from "../assets/logo-color.png";
import logo_no_background from "../assets/logo-no-background.png";
import ece_white from "../assets/ece_florida_white.jpg";
import ece_blue from "../assets/ece_florida_blue.png";
import uf_college_eng from "../assets/uf_college_eng.jpg";
import resume from "../assets/resume.PNG";
import resume2 from "../assets/resume2.PNG";
import resumeS24 from "../assets/resumeS24.PNG";

export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  linkedIn,
  headshot,
  logo_color,
  logo_no_background,
  ece_blue,
  ece_white,
  uf_college_eng,
  resume,
  resume2,
  resumeS24,
};
